import { Button } from "@/components/ui/button"
import { ArrowDown, BarChart3, ChevronDown, ChevronUp, Lightbulb, MessageCircle, PhoneCall } from "lucide-react"
import mixpanel from "mixpanel-browser"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './components/ui/card'
import { LanguageSelector } from "./components/LanguageSelector"

export function LandingPage() {
    const [openFaq, setOpenFaq] = useState<number | null>(null)
    const {t, i18n} = useTranslation()

    const toggleFaq = (index: number) => {
        setOpenFaq(openFaq === index ? null : index)
    }

    const faqs = [
        {
            question: t('faq.question1', 'Is the feedback collected by BoothBits public?'),
            answer: t('faq.answer1', 'No, it is only for you. All information is private, giving you exclusive access to the data, including feedback from your staff to keep both attendees and team under control.'),
        },
        {
            question: t('faq.question2', 'Can I use Boothbits at events of any size?'),
            answer: t('faq.answer2', 'Boothbits is scalable and can adapt to events of any size, from small concerts to large festivals or international conferences. We offer flexible plans that fit the magnitude of your event.'),
        },
        {
            question: t('faq.question3', 'What types of reports can I generate with Boothbits?'),
            answer: t('faq.answer3', 'Boothbits generates detailed reports by category (strengths, issues, requests) and by department (music, staff, security, etc.). You can access visual summaries, interactive charts, and actionable recommendations, all from a centralized dashboard.'),
        },
        {
            question: t('faq.question4', 'Can I customize the feedback surveys?'),
            answer: t('faq.answer4', 'Yes, you can fully customize the surveys to suit the specific needs of your event. Boothbits allows you to adjust the questions and format to obtain the most relevant feedback for you.'),
        },
        {
            question: t('faq.question5', 'How long does it take to see the results?'),
            answer: t('faq.answer5', 'Results are seen almost in real-time. From the moment feedback starts being collected, you can view the analysis on our dashboard. This allows you to make immediate adjustments and plan improvements for future events.'),
        },
        {
            question: t('faq.question6', 'Is it difficult to implement Boothbits at my events?'),
            answer: t('faq.answer6', 'Boothbits is easy to implement. We provide all the necessary tools to ensure the feedback collection process is smooth and does not interrupt the event. Additionally, our team will assist you at all times to ensure the system works optimally.'),
        },
    ]

    const dashboardImg = i18n.language === 'es' ? '/boothbits-landing-dashboard-es.png' : '/boothbits-landing-dashboard-en.png'

    useEffect(() => {
        mixpanel.track_pageview()
    }, [])

    const redirectToSales = () => {
        window.location.href = "https://calendar.app.google/dPWb5XdarVm7popF9"
    }

    const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-[18px] w-[18px] text-primary">
        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd">
        </path>
    </svg>

    return (
        <>
            <div className="min-h-screen bg-gray-900 text-white">
                <header className="container mx-auto px-4 py-6 flex justify-between items-center">
                    <div className="text-2xl font-bold">
                        <a className="flex items-center justify-center" href="/">
                            <img className="h-12" src="/boothbits-logo.svg" alt="BoothBits logo" />
                        </a>
                    </div>
                    <nav className="flex gap-8">
                        <LanguageSelector />
                        <a href="#faqs" className="text-gray-300 hover:text-white transition-colors">
                            {t('header.faqs', 'FAQs')}
                        </a>
                    </nav>
                </header>

                <main>
                    <section id="intro" className="mx-auto flex w-full max-w-7xl flex-col items-center justify-center gap-14 overflow-hidden px-0.5 pb-12 pt-8 max-lg:px-4 lg:gap-16 lg:pb-24 lg:pt-14">
                        <div className="text-center">
                            <h1 className="mb-10 text-4xl font-extrabold tracking-tight lg:mb-12 lg:text-6xl">
                                {t('intro.title', 'Feedback Management improved with AI')}
                                <span className="flex justify-center lg:justify-end mt-4 lg:-rotate-3 lg:translate-y-full whitespace-nowrap items-center gap-0.5 text-base lg:text-xl font-normal tracking-wide">
                                    {t('intro.subtitle', 'better feedback, better events')}
                                    <span className="opacity-100 text-green ml-1">✔</span>
                                </span>
                            </h1>
                            <div className="mx-auto w-fit text-left">
                                <p className="mb-4 text-lg font-medium leading-relaxed text-base-content lg:text-xl">                                    
                                    {t('intro.listTitle', 'Manage experience data from your events')} 
                                </p>
                                <ul className="text-base-secondary mb-8 space-y-0.5 text-lg leading-relaxed">
                                    <li className="flex items-center justify-start gap-2">
                                        {checkIcon}
                                        {t('intro.firstListItem', 'Analyze real feedback from attendees')} 
                                    </li>
                                    <li className="flex items-center justify-start gap-2">
                                        {checkIcon}
                                        {t('intro.secondtListItem', 'Get AI-powered insights and summaries')} 
                                    </li>
                                    <li className="flex items-center justify-start gap-2">
                                        {checkIcon}
                                        {t('intro.thirdListItem', 'Understand ratings at a glance')} 
                                    </li>
                                </ul>
                                <div className="space-y-1.5 flex flex-col max-w-sm">
                                    <Button className="bg-gradient-to-r from-purple-600 to-pink-500 hover:opacity-90 text-lg px-8 py-4" onClick={redirectToSales}>
                                        <PhoneCall className="w-5 h-5 mr-2"/>
                                        {t('callToAction.button', 'I want a demo')}
                                    </Button>
                                    <p className="text-base-secondary text-center text-sm opacity-90">{t('intro.buttonSubtitle', 'Start producing better events')} 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-[1.3rem] border-4 p-1.5 w-full">
                            <img width="1000" height="1000" className="w-full rounded-[1.3rem]" src={dashboardImg} />
                        </div>
                    </section>

                    <section id="how-it-works" className="py-20">
                        <div className="container mx-auto px-4">
                            <h2 className="text-3xl font-bold mb-12 text-center">{t('howItWorks.title', 'How it works')}</h2>
                            <div className="grid md:grid-cols-4 gap-8">
                                {[
                                    {
                                        title: t('howItWorks.step1.title', 'Collect Opinions'),
                                        description: t('howItWorks.step1.description', 'Get insights from attendees through dynamic surveys'),
                                        imgSrc: 'cellphone-form.svg',
                                    },
                                    {
                                        title: t('howItWorks.step2.title', 'AI Analysis'),
                                        description: t('howItWorks.step2.description', 'Our AI processes and analyzes the collected data'),
                                        imgSrc: 'ai-analysis.jpg',
                                    },
                                    {
                                        title: t('howItWorks.step3.title', 'Generate Reports'),
                                        description: t('howItWorks.step3.description', 'Receive detailed reports of your event data'),
                                        imgSrc: 'report-example.png',
                                    },
                                    {
                                        title: t('howItWorks.step4.title', 'Improve Your Events'),
                                        description: t('howItWorks.step4.description', 'Implement AI-driven recommendations'),
                                        imgSrc: 'improvement.png',
                                    },
                                ].map((step, index) => (
                                    <div key={index}>
                                        <Card className='border-2'>
                                            <CardHeader className="bg-pink-200 h-36 relative">
                                                <img src={step.imgSrc} className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover' />
                                                <div className="z-10 w-12 h-12 bg-gradient-to-r from-purple-400 to-pink-500 rounded-full flex items-center justify-center text-2xl font-bold">
                                                    {index + 1}
                                                </div>
                                            </CardHeader>
                                            <CardContent>
                                                <CardTitle>{step.title}</CardTitle>
                                                <CardDescription>{step.description}</CardDescription>
                                            </CardContent>
                                        </Card>
                                        {index < 3 && <ArrowDown className="sm:hidden mx-auto w-8 h-8 text-purple-500 mt-4" />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section id="features" className="py-20">
                        <div className="container mx-auto px-4">
                            <h2 className="text-3xl font-bold mb-12 text-center">{t('features.title', 'Key Features')}</h2>
                            <div className="grid md:grid-cols-3 gap-8">
                                <div className="bg-gray-700 p-6 rounded-lg">
                                    <BarChart3 className="w-12 h-12 text-purple-400 mb-4" />
                                    <h3 className="text-xl font-semibold mb-2">{t('features.feature1.title', 'Real-time Analytics')}</h3>
                                    <p>
                                        {t('features.feature1.description', 'Get instant insights from attendee feedback as it comes in.')}
                                    </p>
                                </div>
                                <div className="bg-gray-700 p-6 rounded-lg">
                                    <MessageCircle className="w-12 h-12 text-purple-400 mb-4" />
                                    <h3 className="text-xl font-semibold mb-2">{t('features.feature2.title', 'Smart Surveys')}</h3>
                                    <p>
                                        {t('features.feature2.description', 'AI-powered surveys that adapt to attendee responses for deeper insights.')}
                                    </p>
                                </div>
                                <div className="bg-gray-700 p-6 rounded-lg">
                                    <Lightbulb className="w-12 h-12 text-purple-400 mb-4" />
                                    <h3 className="text-xl font-semibold mb-2">{t('features.feature3.title', 'Actionable Recommendations')}</h3>
                                    <p>
                                        {t('features.feature3.description', 'Receive AI-generated suggestions to improve your future events.')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="faqs" className=" py-20">
                        <div className="container mx-auto px-4">
                            <h3 className="text-3xl font-bold mb-8 text-center text-white">{t('faqs.title', 'Frequently Asked Questions')}</h3>
                            <div className="max-w-3xl mx-auto space-y-4">
                                {faqs.map((faq, index) => (
                                    <div key={index} className="border border-gray-700 rounded-lg focus-within:ring-2 focus-within:ring-purple-600 transition duration-300 ease-in-out">
                                        <button
                                            className={`flex justify-between items-center w-full p-4 text-left bg-gray-800 hover:bg-gray-700 focus:outline-none transition duration-300 ease-in-out ${
                                                openFaq === index ? "rounded-t-lg" : "rounded-lg"
                                            }`}
                                            onClick={() => toggleFaq(index)}
                                        >
                                            <span className="font-bold text-purple-400">{faq.question}</span>
                                            {openFaq === index ? (
                                                <ChevronUp className="h-5 w-5 text-gray-400" />
                                            ) : (
                                                <ChevronDown className="h-5 w-5 text-gray-400" />
                                            )}
                                        </button>
                                        {openFaq === index && (
                                            <div className="p-4 bg-gray-700 rounded-b-lg">
                                                <p className="text-gray-300">{faq.answer}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className="bg-gray-800 pt-20">
                        <div className="container mx-auto px-4 text-center">
                            <h2 className="text-2xl font-bold mb-8">{t('callToAction.title', 'Let’s improve together, schedule a call.')}</h2>
                            <p className="mb-8">
                                {t('callToAction.description', 'Together, we’ll create a customized experience that aligns with your goals. We believe in doing things right as we grow, building a strong foundation as we strive for excellence in every step.')}
                            </p>
                            <Button className="bg-gradient-to-r from-purple-600 to-pink-500 hover:opacity-90 text-lg px-8 py-4" onClick={redirectToSales}>
                                <PhoneCall className="w-5 h-5 mr-2" />
                                {t('callToAction.button', 'I want a demo')}
                            </Button>
                            <p className="font-bold italic mt-8">{t('callToAction.signature', 'BoothBits Team')}</p>
                        </div>
                    </section>

                    <section className="bg-gray-800 pt-12 pb-20">
                        <div className="flex justify-around max-w-3xl mx-auto space-y-4">
                            <img className="h-[150px]" src="/laptop-dashboard.svg" alt="Laptop Dashboard" />
                            <img className="h-[150px]" src="/cellphone-form.svg" alt="Cellphone form" />
                        </div>
                    </section>
                </main>
                <footer className="bg-gray-900 py-12">
                    <div className="container mx-auto px-4">
                        <div className="flex justify-between gap-8">
                            <img className="h-8" src="/boothbits-logo.svg" alt="BoothBits logo" />
                            <div>
                                <ul className="space-y-2">
                                    <li>
                                        <Link to="/tcs" className="text-gray-400 hover:text-white">
                                            {t('footer.terms', 'Terms and Conditions')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy" className="text-gray-400 hover:text-white">
                                            {t('footer.privacy', 'Privacy Policy')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
                            <p>&copy; 2024 BoothBits. {t('footer.rights', 'All rights reserved.')}</p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
